import type { ComponentProps } from 'solid-js';
import { Show, splitProps, createSignal, onMount } from 'solid-js';
import type { VariantProps } from 'class-variance-authority';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const timelineVariants = cva('timeline', {
  variants: {
    direction: {
      horizontal: 'timeline-horizontal',
      vertical: 'timeline-vertical',
    },
    snapIcon: {
      true: 'timeline-snap-icon',
      false: '',
    },
  },
});
export interface TimelineProps
  extends Omit<ComponentProps<'ul'>, 'color'>,
    VariantProps<typeof timelineVariants> {}

export const Timeline = (props: TimelineProps) => (
  <ul
    {...props}
    class={cn(
      timelineVariants({
        direction: props.direction,
        snapIcon: props.snapIcon,
      }),
      props.class,
    )}
  />
);

const timelineItemVariants = cva('', {
  variants: {
    showLabelBox: {
      true: 'timeline-box',
    },
    position: {
      start: 'timeline-start',
      end: 'timeline-end',
    },
  },
  defaultVariants: {
    showLabelBox: false,
    position: 'start',
  },
});

const timelineItemElementVariants = cva('fill-current', {
  variants: {
    color: {
      default: '',
      neutral: 'bg-neutral fill-neutral',
      primary: 'bg-primary fill-primary',
      secondary: 'bg-secondary fill-secondary',
      accent: 'bg-accent fill-accent',
      info: 'bg-info fill-info',
      success: 'bg-success fill-success',
      warning: 'bg-warning fill-warning',
      error: 'bg-error fill-error',
    },
  },
  defaultVariants: {
    color: 'default',
  },
});

export interface TimelineItemProps
  extends Omit<ComponentProps<'span'>, 'color'>,
    VariantProps<typeof timelineItemVariants>,
    VariantProps<typeof timelineItemElementVariants> {
  icon?: IconDefinition;
}
export const TimelineItem = (props: TimelineItemProps) => {
  let ref: HTMLLIElement | undefined;
  const [previous, setPrevious] = createSignal<Element | null>(null);
  const [next, setNext] = createSignal<Element | null>(null);
  const [, otherProps] = splitProps(props, [
    'icon',
    'showLabelBox',
    'position',
    'color',
  ]);
  onMount(() => {
    if (!ref) {
      return;
    }

    setPrevious(ref.previousElementSibling || null);
    setNext(ref.nextElementSibling || null);
  });
  return (
    <li ref={(el) => (ref = el)} data-color={props.color}>
      <Show when={previous()}>
        <hr class={cn(timelineItemElementVariants({ color: props.color }))} />
      </Show>
      <span
        {...otherProps}
        class={cn(
          timelineItemVariants({
            showLabelBox: props.showLabelBox,
            position: props.position,
          }),
          props.class,
        )}
      >
        {props.children}
      </span>
      <Show when={props.icon}>
        {(icon) => {
          return (
            <div class={'timeline-middle'}>
              <FontAwesomeIcon
                icon={icon()}
                class={cn(
                  timelineItemElementVariants({ color: props.color }),
                  'w-4 aspect-square bg-transparent',
                )}
              />
            </div>
          );
        }}
      </Show>
      <Show when={next()}>
        <hr
          class={cn(
            timelineItemElementVariants({
              color: next()?.getAttribute('data-color') as typeof props.color,
            }),
          )}
        />
      </Show>
    </li>
  );
};
