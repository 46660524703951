import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { createSignal } from 'solid-js';
import { type ComponentProps, Index, splitProps } from 'solid-js';
import { cn } from '~/ui/methods/classNames';

const ratingVariants = cva('rating', {
  variants: {
    size: {
      xs: 'rating-xs',
      sm: 'rating-sm',
      md: 'rating-md',
      lg: 'rating-lg',
    },
    half: {
      true: 'rating-half',
    },
  },
});

const ratingItemVariants = cva('rating mask', {
  variants: {
    color: {
      default: '',
      primary: 'bg-primary',
      secondary: 'bg-secondary',
      accent: 'bg-accent',
      info: 'bg-info',
      success: 'bg-success',
      warning: 'bg-warning',
      error: 'bg-error',
    },
    mask: {
      triangle: 'mask-triangle',
      hexagon: 'mask-hexagon',
      squircle: 'mask-squircle',
      star: 'mask-star-2',
      heart: 'mask-heart',
    },
    maskHalf: {
      false: '',
      first: 'mask-half-1',
      second: 'mask-half-2',
    },
  },
});

export interface RatingProps
  extends Omit<ComponentProps<'div'>, 'color'>,
    VariantProps<typeof ratingVariants>,
    Omit<VariantProps<typeof ratingItemVariants>, 'maskHalf'> {
  name?: string;
  length?: number;
}

export const Rating = (props: RatingProps) => {
  const [length] = createSignal((props.length || 5) * (props.half ? 2 : 1));
  const [styleProps, otherProps] = splitProps(props, [
    'color',
    'size',
    'half',
    'mask',
  ]);
  const [inputProps, restProps] = splitProps(otherProps, ['name']);
  return (
    <div {...restProps} class={cn(ratingVariants(styleProps), props.class)}>
      <input type='radio' {...inputProps} class='rating-hidden' />
      <Index each={Array.from({ length: length() }, (_, i) => i)}>
        {(_, i) => (
          <input
            type='radio'
            {...inputProps}
            class={cn(
              ratingItemVariants({
                color: props.color,
                mask: props.mask,
                maskHalf: props.half
                  ? i % 2 === 0
                    ? 'first'
                    : 'second'
                  : false,
              }),
            )}
          />
        )}
      </Index>
    </div>
  );
};
