import type { SbBlokData } from '@storyblok/js';
import camelcase from 'camelcase';
import { For, Show, splitProps, type Component } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import * as components from '~/storyblok/components';
export interface StoryBlokProps {
  blok: SbBlokData;
  [prop: string]: unknown;
}

/** @scope * */
export const Storyblok = (props: StoryBlokProps) => {
  const [local, _props] = splitProps(props.blok, [
    'component',
    'children',
    'element',
  ]);
  const otherProps = { ..._props } as Record<string, unknown>;
  const key = camelcase(props.blok.component || '', { pascalCase: true });

  if (!props.blok) {
    throw new Error('Cannot render StoryblokComponent.');
  }

  for (const key in otherProps) {
    const prop = otherProps[key] as unknown as {
      fieldtype: string;
      filename: string;
    };
    if (prop?.fieldtype === 'asset') {
      otherProps[key] = prop.filename;
    }
  }

  const Component =
    //@ts-expect-error Text component has required field that SbBlokData does not satisfy
    (components as Record<string, Component<Partial<StoryBlokProps>>>)?.[key];

  return (
    <>
      <Show when={Component}>
        {(c) => (
          <Dynamic {...otherProps} component={c()}>
            <For
              each={
                !Array.isArray(local.children)
                  ? [local.children]
                  : local.children
              }
            >
              {(child) => <Storyblok blok={child} />}
            </For>
          </Dynamic>
        )}
      </Show>
      <Show when={!Component}>
        <Dynamic
          component={(local.element as string) || local.component}
          {...otherProps}
        >
          {Array.isArray(local.children) ? (
            <For each={local.children}>
              {(child) => <Storyblok blok={child} />}
            </For>
          ) : (
            local.children
          )}
        </Dynamic>
      </Show>
    </>
  );
};
