import type { ValidComponent } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import type { TextStoryblok } from '~/storyblok/types';

const Fragment = (props: { children: ValidComponent }) => <>{props.children}</>;

export interface TextProps extends TextStoryblok {}

export const Text = (props: TextProps) => {
  return (
    <Dynamic component={props.tag ? props.tag : Fragment} class={props.class}>
      {props.content}
    </Dynamic>
  );
};
