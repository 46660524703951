import { cva, type VariantProps } from 'class-variance-authority';
import type { JSX, ComponentProps } from 'solid-js';
import { splitProps } from 'solid-js';
import { cn } from '~/ui/methods/classNames';

const swapVariants = cva('swap', {
  variants: {
    animation: {
      fade: '',
      rotate: 'swap-rotate',
      flip: 'swap-flip',
    },
  },
  defaultVariants: {
    animation: 'fade',
  },
});

export interface SwapProps
  extends VariantProps<typeof swapVariants>,
    ComponentProps<'div'> {
  on: JSX.Element;
  off: JSX.Element;
  indeterminate?: JSX.Element;
}

export const Swap = (props: SwapProps) => {
  const [className, rest] = splitProps(props, ['class']);
  return (
    <label class={cn(swapVariants(rest), className)}>
      <input type='checkbox' />
      <div class='swap-on'>{props.on}</div>
      <div class='swap-off'>{props.off}</div>
      {props.indeterminate && (
        <div class='swap-indeterminate'>{props.indeterminate}</div>
      )}
    </label>
  );
};
