import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { type ComponentProps, splitProps } from 'solid-js';
import { cn } from '~/ui/methods/classNames';

const artboardVariants = cva('artboard artboard-demo', {
  variants: {
    orientation: {
      vertical: 'artboard-vertical',
      horizontal: 'artboard-horizontal',
    },
    size: {
      xs: 'phone-1',
      sm: 'phone-2',
      md: 'phone-3',
      lg: 'phone-4',
      xl: 'phone-5',
      '2xl': 'phone-6',
    },
  },
  defaultVariants: {
    orientation: 'vertical',
    size: 'md',
  },
});

export interface ArtboardProps
  extends VariantProps<typeof artboardVariants>,
    ComponentProps<'div'> {}
export const Artboard = (props: ArtboardProps) => {
  const [styleProps, otherProps] = splitProps(props, ['orientation', 'size']);
  return (
    <div
      {...otherProps}
      class={cn(artboardVariants(styleProps), props.class)}
    />
  );
};
