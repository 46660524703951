import type { ComponentProps } from 'solid-js';
import { playerStore } from '~/players/stores/playerStore';
import { cn } from '~/ui/methods/classNames';

export const FooterWrapper = (props: ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      class={cn('relative z-20', {
        'pb-24': playerStore.visibility === 'shown',
      })}
    />
  );
};
