import type { ComponentProps, Component } from 'solid-js';
import { createSignal, createEffect } from 'solid-js';

import { cn } from '~/ui/methods/classNames';

export interface DiffProps extends Omit<ComponentProps<'div'>, 'children'> {
  children: [
    ReturnType<Component<DiffProps>>,
    ReturnType<Component<DiffProps>>,
  ];
}

export const Diff: Component<DiffProps> = (props) => {
  return (
    <div {...props} class={cn('diff', props.class)}>
      {props.children}
      <div class='diff-resizer' />
    </div>
  );
};
export const DiffItem = (props: ComponentProps<'div'>) => {
  let ref: HTMLDivElement | null = null;
  const [className, setClassName] = createSignal('');

  createEffect(() => {
    if (!ref?.parentNode) {
      return;
    }
    setClassName(
      ref?.parentNode.children[0] === ref ? 'diff-item-1' : 'diff-item-2',
    );
  });

  return (
    <div
      ref={(el) => (ref = el)}
      {...props}
      class={cn(className(), props.class)}
    >
      {props.children}
    </div>
  );
};
