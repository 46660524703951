import { type ComponentProps } from 'solid-js';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';
import { splitProps } from 'solid-js';
import { Show } from 'solid-js';
import { helperTextVariants } from '~/ui/methods/helperTextVariants';

const fileInputVariants = cva('input file-input', {
  variants: {
    style: {
      default: '',
      primary: 'input-primary file-input-primary',
      secondary: 'input-secondary file-input-secondary',
      accent: 'input-accent file-input-accent',
      info: 'input-info file-input-info',
      success: 'input-success file-input-success',
      warning: 'input-warning file-input-warning',
      error: 'input-error file-input-error',
    },
    size: {
      xs: 'file-input-xs',
      sm: 'file-input-sm',
      md: 'file-input-md',
      lg: 'file-input-lg',
    },
  },
});

export interface FileInputProps
  extends Omit<ComponentProps<'input'>, 'style' | 'size'>,
    VariantProps<typeof fileInputVariants>,
    VariantProps<typeof helperTextVariants> {
  label?: string;
  altLabel?: string;
  helpText?: string;
  altHelpText?: string;
}

export const FileInput = (props: FileInputProps) => {
  const [styleProps, restProps] = splitProps(props, ['style', 'size']);

  return (
    <label class='form-control input-control'>
      <Show when={props.label || props.altLabel}>
        <div class='label'>
          <Show when={props.label}>
            <span class='label-text'>{props.label}</span>
          </Show>
          <Show when={props.altLabel}>
            <span class='label-text-alt text-current'>{props.altLabel}</span>
          </Show>
        </div>
      </Show>
      <input
        {...restProps}
        type='file'
        class={cn(fileInputVariants(styleProps), props.class)}
      />
      <Show when={props.helpText || props.altHelpText}>
        <div class={cn(helperTextVariants({ status: props.status }))}>
          <Show when={props.helpText}>
            <span class='label-text-alt text-current'>{props.helpText}</span>
          </Show>
          <Show when={props.altHelpText}>
            <span class='label-text-alt text-current'>{props.altHelpText}</span>
          </Show>
        </div>
      </Show>
    </label>
  );
};
