import throttle from 'lodash.throttle';
import { createEffect, createSignal, on, onMount } from 'solid-js';
import { awaitAnimations } from '~/common/methods/awaitAnimations';
import { HideControls } from '~/players/components/HideControls';
import { TrackControls } from '~/players/components/TrackControls';
import { Transport } from '~/players/components/Transport';
import { playerStore } from '~/players/stores/playerStore';
import { TrackDetails } from '~/tracks/components/TrackDetails';
import { cn } from '~/ui/methods/classNames';

export const FloatingPlayer = () => {
  let sectionRef: HTMLDivElement | undefined;
  const [initialLoad, setInitialLoad] = createSignal(true);
  const [trackCurrentTime, setTrackCurrentTime] = createSignal<number | null>(
    null,
  );

  const onTimeUpdate = () => {
    if (playerStore.seeking || trackCurrentTime()) {
      return;
    }

    requestAnimationFrame(() => {
      playerStore.currentTime = playerStore.audioElement?.currentTime || 0;
    });
  };

  const handleLoadedMetadata = () => {
    if (!playerStore.audioElement?.duration) {
      return;
    }
    playerStore.duration = playerStore.audioElement?.duration || 0;
  };

  const handleCanPlay = async () => {
    const currentTime = trackCurrentTime();
    if (currentTime && playerStore.audioElement) {
      playerStore.audioElement.currentTime = currentTime;
      setTrackCurrentTime(null);
    }
    if (!playerStore.paused) {
      await playerStore.audioElement?.play();
    }
  };

  const handleEnded = () => {
    const index =
      playerStore.tracks?.findIndex(
        (track) => track?.id === playerStore.track?.id,
      ) ?? -1;
    if (
      !playerStore.tracks ||
      index <= -1 ||
      index === playerStore.tracks?.length - 1
    ) {
      return;
    }
    const track = playerStore.tracks[index + 1];

    if (!playerStore.repeating && track) {
      playerStore.track = track?.id;
    }

    playerStore.audioElement?.play();
  };

  onMount(() => {
    if (!sectionRef) {
      return;
    }
    sectionRef.classList.add('translate-y-full', 'hidden');
    awaitAnimations([sectionRef]).then(() => {
      setInitialLoad(false);
    });
  });

  createEffect(
    on([() => playerStore.visibility, initialLoad], () => {
      if (initialLoad()) {
        return;
      }
      switch (playerStore.visibility) {
        case 'shown':
          sectionRef?.classList.remove('xyz-in');
          break;
        case 'hidden':
          sectionRef?.classList.add('xyz-out');
          if (!sectionRef) {
            return;
          }
          awaitAnimations([sectionRef]).then(() => {
            sectionRef?.classList.add('translate-y-full', 'hidden');
          });
          break;
        case 'animating':
          sectionRef?.classList.remove('xyz-out', 'translate-y-full', 'hidden');
          sectionRef?.classList.add('xyz-in');

          if (!sectionRef) {
            return;
          }
          awaitAnimations([sectionRef]).then(() => {
            playerStore.visibility = 'shown';
          });
          break;
        default:
          break;
      }
    }),
  );

  createEffect(
    on(
      () => playerStore.volume,
      () => {
        if (!playerStore.audioElement) {
          return;
        }
        playerStore.audioElement.volume = playerStore.volume / 100;
      },
    ),
  );

  createEffect(
    on(
      () => playerStore.paused,
      (paused) => {
        if (paused === playerStore.audioElement?.paused) {
          return;
        }

        if (paused) {
          playerStore.audioElement?.pause();
          return;
        }
        if (!playerStore.src || !playerStore.audioElement?.src) {
          return;
        }
        playerStore.audioElement?.play();
      },
    ),
  );

  return (
    <section
      ref={sectionRef}
      xyz='origin-bottom fade-1 down-100%'
      class={cn(
        'hidden',
        'border-t',
        'transition-all',
        'border-base-content/40',
        'fixed',
        'left-0',
        'flex',
        'flex-col',
        'lg:flex-row',
        'items-center',
        'justify-between',
        'px-3',
        'w-full',
        'h-52',
        'lg:h-24',
        'bottom-0',
        'bg-gradient-main',
        'bg-accent',
        'z-30',
        {
          'h-52': playerStore.isExpanded,
          'h-28': !playerStore.isExpanded,
        },
      )}
    >
      <TrackDetails />
      <Transport />
      <TrackControls />
      <HideControls />
      <audio
        onCanPlay={handleCanPlay}
        onEnded={handleEnded}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={throttle(onTimeUpdate, 100)}
        ref={(el) => (playerStore.audioElement = el)}
        src={playerStore.src ? URL.createObjectURL(playerStore.src) : undefined}
      />
      <div
        class={'absolute'}
        ref={(el) => (playerStore.modalContainer = el)}
      ></div>
    </section>
  );
};
