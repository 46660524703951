import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { splitProps } from 'solid-js';
import type { ComponentProps } from 'solid-js';
import { children, createEffect } from 'solid-js';
import { cn } from '~/ui/methods/classNames';

export const Code = (props: ComponentProps<'div'>) => {
  const c = children(() => props.children);

  createEffect(() => {
    let children = c();
    if (!Array.isArray(children)) {
      children = [children];
    }

    children?.forEach((item, i) => {
      if (!(item instanceof HTMLElement)) {
        return;
      }
      item.dataset.prefix = (i + 1).toString();
    });
  });
  return (
    <div {...props} class={cn('mockup-code', props.class)}>
      {c()}
    </div>
  );
};
const codeLineVariants = cva('', {
  variants: {
    color: {
      default: '',
      primary: 'bg-primary text-primary-content',
      secondary: 'bg-secondary text-secondary-content',
      accent: 'bg-accent text-accent-content',
      info: 'bg-info text-info-content',
      success: 'bg-success text-success-content',
      warning: 'bg-warning text-warning-content',
      error: 'bg-error text-error-content',
    },
  },
  defaultVariants: {
    color: 'default',
  },
});
export interface CodeLineProps
  extends Omit<ComponentProps<'code'>, 'color'>,
    VariantProps<typeof codeLineVariants> {}

export const CodeLine = (props: CodeLineProps) => {
  const [styleProps, otherProps] = splitProps(props, ['color']);
  return (
    <pre class={cn(codeLineVariants(styleProps), props.class)}>
      <code {...otherProps} />
    </pre>
  );
};
