import type { ComponentProps } from 'solid-js';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { Show } from 'solid-js';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const breadcrumbsVariants = cva('breadcrumbs', {
  variants: {
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

export interface BreadcrumbsProps
  extends ComponentProps<'ol'>,
    VariantProps<typeof breadcrumbsVariants> {}

export const Breadcrumbs = (props: BreadcrumbsProps) => (
  <nav class={cn(breadcrumbsVariants({ size: props.size }), props.class)}>
    <ol {...props}></ol>
  </nav>
);

export interface BreadcrumbProps extends ComponentProps<'a'> {
  icon?: IconDefinition;
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  const content = (
    <>
      <Show when={props.icon}>
        {(icon) => {
          return (
            <FontAwesomeIcon
              icon={icon()}
              class={'w-4 aspect-square fill-base-content'}
            />
          );
        }}
      </Show>
      {props.children}
    </>
  );
  return (
    <li>
      <Show when={props.href}>
        <a
          {...(props as Record<string, unknown>)}
          class={cn('inline-flex gap-2 items-center', props.class)}
        >
          {content}
        </a>
      </Show>
      <Show when={!props.href}>
        <span
          {...(props as Record<string, unknown>)}
          class={cn('inline-flex gap-2 items-center', props.class)}
        >
          {content}
        </span>
      </Show>
    </li>
  );
};
