import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { nanoid } from 'nanoid';
import type { JSX } from 'solid-js';
import { type ComponentProps, Show } from 'solid-js';
import { cn } from '~/ui/methods/classNames';

const drawerVariants = cva('drawer flex', {
  variants: {
    position: {
      left: '',
      right: 'drawer-end',
    },
  },
  defaultVariants: {
    position: 'left',
  },
});

export interface DrawerProps
  extends ComponentProps<'div'>,
    VariantProps<typeof drawerVariants> {
  id?: string;
  body?: JSX.Element;
}
export const Drawer = (props: DrawerProps) => {
  const id = props.id || nanoid();
  return (
    <div class={cn(drawerVariants({ position: props.position }), props.class)}>
      <input id={id} type='checkbox' class='drawer-toggle' />
      <Show when={props.body}>{(body) => body()}</Show>
      <div class='drawer-side'>
        <label
          for={id}
          aria-label='close sidebar'
          class='drawer-overlay'
        ></label>

        {props.children}
      </div>
    </div>
  );
};
