import { children, createMemo, type JSXElement } from 'solid-js';
import type { GridItemStoryblok, GridStoryblok } from '~/storyblok/types';
import { cn } from '~/ui/methods/classNames';

export interface GridProps extends Omit<GridStoryblok, 'children'> {
  children: JSXElement;
}

const GRID_MAP = {
  '1': 'sm:grid-cols-1',
  '2': 'sm:grid-cols-2',
  '3': 'sm:grid-cols-3',
  '4': 'sm:grid-cols-4',
  '5': 'sm:grid-cols-5',
  '6': 'sm:grid-cols-6',
};

export const Grid = (props: GridProps) => {
  const resolved = children(() => props.children);
  const childCount = createMemo(() => {
    const children = resolved();
    return Array.isArray(children)
      ? children.filter((child) => !!child).length
      : 1;
  });
  return (
    <div
      class={cn(
        'grid grid-cols-1 justify-self-center mx-auto gap-4',
        GRID_MAP[childCount().toString() as keyof typeof GRID_MAP],
      )}
    >
      {resolved()}
    </div>
  );
};

export interface GridItemProps extends Omit<GridItemStoryblok, 'children'> {
  children: JSXElement;
}

export const GridItem = (props: GridItemProps) => {
  return <div class={props.class}>{props.children}</div>;
};
