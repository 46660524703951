import { faBackwardStep } from '@fortawesome/pro-solid-svg-icons/faBackwardStep';
import { playerStore } from '~/players/stores/playerStore';
import Button from '~/ui/components/actions/Button';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { cn } from '~/ui/methods/classNames';

export const PreviousTrackButton = () => {
  return (
    <Button
      id='next-track-button'
      variant={'link'}
      color={'secondary'}
      size={'sm'}
      class={cn('px-0', 'w-8')}
      disabled={!playerStore.prev}
      onClick={playerStore.previousTrack}
      onMouseOver={() =>
        playerStore.prev ? playerStore.prefetchTrack(playerStore.prev) : null
      }
    >
      <FontAwesomeIcon icon={faBackwardStep} class={'h-4'} />
    </Button>
  );
};
