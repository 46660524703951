import { cva, type VariantProps } from 'class-variance-authority';
import { type ComponentProps, Show } from 'solid-js';
import { cn } from '~/ui/methods/classNames';

const heroVariants = cva('hero-content text-center text-neutral-content', {
  variants: {
    imagePosition: {
      start: 'flex-row',
      end: 'flex-row-reverse',
      overlay: '',
    },
  },
});

export interface HeroProps
  extends ComponentProps<'div'>,
    VariantProps<typeof heroVariants> {
  image?: string;
}

export const Hero = (props: HeroProps) => (
  <div
    class={cn('hero', props.class)}
    style={
      props.imagePosition === 'overlay'
        ? {
            'background-image': `url(${props.image})`,
          }
        : undefined
    }
  >
    <Show when={props.imagePosition === 'overlay'}>
      <div class='hero-overlay bg-opacity-60' />
    </Show>
    <div class={cn(heroVariants({ imagePosition: props.imagePosition }))}>
      <Show when={props.imagePosition !== 'overlay' && props.image}>
        <img src={props.image} />
      </Show>
      <div class='max-w-2xl'>{props.children}</div>
    </div>
  </div>
);
