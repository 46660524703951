import type { ArtboardProps } from '~/ui/components/layout/Artboard';
import { Artboard } from '~/ui/components/layout/Artboard';

export interface PhoneProps extends ArtboardProps {}
export const Phone = (props: PhoneProps) => {
  return (
    <div class={'mockup-phone'}>
      <div class='camera' />
      <div class='display'>
        <Artboard {...props} />
      </div>
    </div>
  );
};
