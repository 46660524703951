import { type ComponentProps } from 'solid-js';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';

const statsVariants = cva('stats shadow', {
  variants: {
    direction: {
      horizontal: 'stats-horizontal',
      vertical: 'stats-vertical',
    },
  },
  defaultVariants: {
    direction: 'horizontal',
  },
});

export interface StatsProps
  extends VariantProps<typeof statsVariants>,
    ComponentProps<'div'> {}

export const Stats = (props: StatsProps) => (
  <div
    {...props}
    class={cn(statsVariants({ direction: props.direction }), props.direction)}
  />
);
export const Stat = (props: ComponentProps<'div'>) => (
  <div {...props} class={cn('stat', props.class)} />
);

export const StatFigure = (props: ComponentProps<'div'>) => (
  <div {...props} class={cn('stat-figure', props.class)} />
);

export const StatTitle = (props: ComponentProps<'h3'>) => (
  <h3 {...props} class={cn('stat-title', props.class)} />
);

export const StatValue = (props: ComponentProps<'span'>) => (
  <span {...props} class={cn('stat-value', props.class)} />
);

export const StatDescription = (props: ComponentProps<'p'>) => (
  <p {...props} class={cn('stat-desc', props.class)} />
);
