import { type ComponentProps } from 'solid-js';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';

const kbdVariants = cva('kbd', {
  variants: {
    size: {
      xs: 'kbd-xs',
      sm: 'kbd-sm',
      md: 'kbd-md',
      lg: 'kbd-lg',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface KbdProps
  extends VariantProps<typeof kbdVariants>,
    ComponentProps<'kbd'> {}

export const Kbd = (props: KbdProps) => {
  return (
    <kbd class={cn(kbdVariants({ size: props.size }), props.class)}>
      {props.children}
    </kbd>
  );
};
