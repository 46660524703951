import {
  type ComponentProps,
  Show,
  Index,
  children,
  createEffect,
} from 'solid-js';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';

const scrollToEl = (el?: HTMLElement) => {
  const carousel = el?.closest('.carousel') as HTMLElement;
  const snap = (carousel.dataset.snap || 'center') as ScrollLogicalPosition;
  el?.scrollIntoView({
    inline: snap,
    block: snap,
    behavior: 'smooth',
  });
};

export const carouselVariants = cva('carousel rounded-box', {
  variants: {
    snap: {
      start: 'carousel-start',
      center: 'carousel-center',
      end: 'carousel-end',
    },
    direction: {
      horizontal: '',
      vertical: 'carousel-vertical',
    },
  },
  defaultVariants: {
    snap: 'start',
    direction: 'horizontal',
  },
});

export interface CarouselProps
  extends ComponentProps<'div'>,
    VariantProps<typeof carouselVariants> {
  showPrevNext?: boolean;
  indicators?: boolean;
}

export const Carousel = (props: CarouselProps) => {
  let carousel: HTMLDivElement | null = null;
  const resolved = children(() => props.children);

  const setActive = (index: number) => {
    if (!carousel) {
      return;
    }
    carousel.dataset.active = index.toString();
    const el = (resolved() as HTMLElement[])?.find((el, i) => {
      if (i === index) {
        return true;
      }
    });
    scrollToEl(el);
  };

  const prev = () => {
    if (!carousel) {
      return;
    }
    const current = carousel.dataset.active || '0';
    const newActive =
      current === '0'
        ? (resolved() as HTMLElement[])?.length - 1
        : parseInt(current) - 1;
    setActive(newActive);
  };

  const next = () => {
    if (!carousel) {
      return;
    }
    const current = carousel.dataset.active || '0';
    const newActive =
      current === ((resolved() as HTMLElement[])?.length - 1)?.toString()
        ? 0
        : parseInt(current) + 1;
    setActive(newActive);
  };

  return (
    <>
      <div
        data-active={0}
        data-snap={props.snap}
        ref={(el) => (carousel = el)}
        class={cn(
          carouselVariants({
            snap: props.snap,
            direction: props.direction,
          }),
        )}
      >
        {resolved()}
        <Show when={props.showPrevNext}>
          <div class='absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2'>
            <button class='btn btn-circle' onClick={prev}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                class='w-5 fill-base-content'
              />
            </button>
            <button class='btn btn-circle' onClick={next}>
              <FontAwesomeIcon
                icon={faChevronRight}
                class='w-5 fill-base-content'
              />
            </button>
          </div>
        </Show>
      </div>
      <Show when={props.indicators}>
        <div class='flex justify-center w-full py-2 gap-2'>
          <Index each={new Array((resolved() as HTMLElement[])?.length || 1)}>
            {(_, i) => (
              <button
                class='btn btn-xs'
                onClick={() => {
                  const el = carousel?.childNodes[i] as HTMLElement;
                  scrollToEl(el);
                }}
              >
                {(i + 1).toString()}
              </button>
            )}
          </Index>
        </div>
      </Show>
    </>
  );
};
export const CarouselItem = (props: ComponentProps<'img'>) => {
  let ref: HTMLImageElement;

  createEffect(() => {
    if (!ref) {
      return;
    }
    const carousel = ref.closest('.carousel') as HTMLElement;
    const snap = (carousel.dataset.snap || 'center') as NonNullable<
      CarouselProps['snap']
    >;
    const carouselObserver = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (!entry.isIntersecting || !ref || !ref.complete) {
            return;
          }
          const index = Array.from(carousel.childNodes).findIndex(
            (el) => el === ref.closest('.carousel-item'),
          );
          carousel.dataset.active = index.toString();
        }),
      {
        root: carousel,
        rootMargin: {
          center: '-50% -50% -50% -50%',
          end: '0% 0px 0px -80%',
          start: '0px -100% -100% 0px',
        }[snap],
        threshold: snap === 'end' ? 0.33 : 0,
      },
    );
    carouselObserver.observe(ref);
  });
  return (
    <div class={cn('carousel-item relative')}>
      <img ref={(el) => (ref = el)} {...props} />
    </div>
  );
};
