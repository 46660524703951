import camelcase from 'camelcase';
import { cva, type VariantProps } from 'class-variance-authority';
import { createResource, Show } from 'solid-js';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { cn } from '~/ui/methods/classNames';

const COLLECTION_MAP = {
  solid: 'pro-solid-svg-icons',
  regular: 'pro-regular-svg-icons',
  light: 'pro-light-svg-icons',
  thin: 'pro-thin-svg-icons',
  duotone: 'pro-duotone-svg-icons',
  brands: 'free-brands-svg-icons',
};
const iconVariants = cva('', {
  variants: {
    size: {
      xs: 'w-4 h-4',
      sm: 'w-6 h-6',
      md: 'w-8 h-8',
      lg: 'w-12 h-12',
      xl: 'w-16 h-16',
    },
    color: {
      primary: 'text-primary',
      'primary-content': 'text-primary-content',
      secondary: 'text-secondary',
      'secondary-content': 'text-secondary-content',
      accent: 'text-accent',
      'accent-content': 'text-accent-content',
      neutral: 'text-neutral',
      'neutral-content': 'text-neutral-content',
      info: 'text-info',
      'info-content': 'text-info-content',
      success: 'text-success',
      'success-content': 'text-success-content',
      warning: 'text-warning',
      'warning-content': 'text-warning-content',
      error: 'text-error',
      'error-content': 'text-error-content',
      'base-100': 'text-base-100',
      'base-200': 'text-base-200',
      'base-300': 'text-base-300',
      'base-content': 'text-base-content',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface IconProps extends VariantProps<typeof iconVariants> {
  collection: keyof typeof COLLECTION_MAP;
  icon: string;
}

export const Icon = (props: IconProps) => {
  const [icon] = createResource(() => {
    const collection = COLLECTION_MAP[props.collection];
    const icon = `fa${camelcase(props.icon, { pascalCase: true })}`;
    return import(`@fortawesome/${collection}/${icon}`).then(
      (module) => module[icon],
    );
  });
  return (
    <Show when={icon()}>
      {(icon) => (
        <FontAwesomeIcon
          class={cn(iconVariants({ size: props.size, color: props.color }))}
          icon={icon()}
        />
      )}
    </Show>
  );
};
