import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { createSignal, Show } from 'solid-js';
import type { YoutubeEmbedStoryblok } from '~/storyblok/types';
import Button from '~/ui/components/actions/Button';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { cn } from '~/ui/methods/classNames';

export interface YoutubeEmbedProps extends YoutubeEmbedStoryblok {}

export const YoutubeEmbed = (props: YoutubeEmbedProps) => {
  const [playing, setPlaying] = createSignal(false);
  const handleClick = () => {
    setPlaying(true);
  };
  return (
    <div
      class={cn(
        'relative w-full aspect-video max-w-5xl justify-self-center mx-auto',
        props.class,
      )}
    >
      <iframe
        src={`https://www.youtube.com/embed/${props.videoId}${playing() ? '?autoplay=1' : ''}`}
        title={props.title}
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        class={'w-full h-full'}
        allowfullscreen={props.allowfullscreen}
      ></iframe>
      <Show when={!playing()}>
        <img
          class={'absolute inset-0 w-full h-full m-0'}
          src={
            (props.thumbnail as unknown as string) ||
            `https://img.youtube.com/vi/${props.videoId}/maxresdefault.jpg`
          }
          alt={props.title}
        />
        <Button
          round
          size='lg'
          color={'secondary'}
          onClick={handleClick}
          class={
            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 backdrop-blur-lg'
          }
        >
          <FontAwesomeIcon class={'h-6'} icon={faPlay} />
        </Button>
      </Show>
    </div>
  );
};
