import {
  Collapse,
  CollapseTitle,
  CollapseContent,
} from '~/ui/components/data-display/Collapse';
import { nanoid } from 'nanoid';
import { children, createEffect, type ComponentProps } from 'solid-js';

export const Accordion = (props: ComponentProps<'div'> & { name?: string }) => {
  const c = children(() => props.children);
  const name = props.name || nanoid();
  createEffect(() => {
    let children = c();
    if (!Array.isArray(children)) {
      children = [children];
    }

    children?.forEach((item) => {
      if (!(item instanceof HTMLElement)) {
        return;
      }
      const input = item.querySelector<HTMLInputElement>('input[type=radio]');
      if (!input) {
        return;
      }
      input.name = name;
    });
  });

  return <div {...props}>{c()}</div>;
};

export interface AccordionItemProps extends ComponentProps<typeof Collapse> {
  value?: string;
}

export const AccordionItem = (props: AccordionItemProps) => (
  <Collapse type='radio' {...props} />
);

export const AccordionTitle = CollapseTitle;

export const AccordionContent = CollapseContent;
