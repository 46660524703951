import { type ComponentProps } from 'solid-js';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';

const tableVariants = cva('table', {
  variants: {
    size: {
      xs: 'table-xs',
      sm: 'table-sm',
      md: 'table-md',
      lg: 'table-lg',
    },
    zebra: {
      true: 'table-zebra',
    },
    pinRows: {
      true: 'table-pin-rows',
    },
    pinColumns: {
      true: 'table-pin-cols',
    },
  },
  defaultVariants: {
    size: 'md',
    zebra: false,
    pinRows: false,
    pinColumns: false,
  },
});

export interface TableProps
  extends ComponentProps<'table'>,
    VariantProps<typeof tableVariants> {}

export const Table = (props: TableProps) => (
  <table
    {...props}
    class={cn(
      tableVariants({
        size: props.size,
        zebra: props.zebra,
        pinRows: props.pinRows,
        pinColumns: props.pinColumns,
      }),
      props.class,
    )}
  />
);

export const TableHead = (props: ComponentProps<'thead'>) => (
  <thead {...props} />
);

export const TableBody = (props: ComponentProps<'tbody'>) => (
  <tbody {...props} />
);

export const TableFoot = (props: ComponentProps<'tfoot'>) => (
  <tfoot {...props} />
);

export const TableRow = (props: ComponentProps<'tr'>) => <tr {...props} />;

export const TableHeader = (props: ComponentProps<'th'>) => <th {...props} />;

export const TableCell = (props: ComponentProps<'td'>) => <td {...props} />;
