import { type ComponentProps, Show, splitProps } from 'solid-js';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';

const radioVariants = cva('radio', {
  variants: {
    color: {
      default: '',
      primary: 'radio-primary',
      secondary: 'radio-secondary',
      accent: 'radio-accent',
      info: 'radio-info',
      success: 'radio-success',
      warning: 'radio-warning',
      error: 'radio-error',
    },
    size: {
      xs: 'radio-xs',
      sm: 'radio-sm',
      md: 'radio-md',
      lg: 'radio-lg',
    },
  },
});

const formControlVariantsVariants = cva('form-control checkbox-control', {
  variants: {
    color: {
      default: '',
      primary: 'checkbox-control-primary',
      secondary: 'checkbox-control-secondary',
      accent: 'checkbox-control-accent',
      info: 'checkbox-control-info',
      success: 'checkbox-control-success',
      warning: 'checkbox-control-warning',
      error: 'checkbox-control-error',
    },
    size: {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
    },
  },
});

export interface RadioProps
  extends Omit<ComponentProps<'input'>, 'color' | 'size'>,
    VariantProps<typeof radioVariants> {
  label?: string;
  labelPosition?: 'start' | 'end';
}

export const Radio = (props: RadioProps) => {
  const [styleProps, restProps] = splitProps(props, ['color', 'size']);
  return (
    <div
      class={formControlVariantsVariants({
        color: props.color,
        size: props.size,
      })}
    >
      <label class='label cursor-pointer gap-2'>
        <Show when={!props.labelPosition || props.labelPosition === 'start'}>
          <span class='label-text'>{props.label}</span>
        </Show>
        <input
          {...restProps}
          type='radio'
          class={cn(radioVariants(styleProps), props.class)}
        />
        <Show when={props.labelPosition === 'end'}>
          <span class='label-text'>{props.label}</span>
        </Show>
      </label>
    </div>
  );
};
