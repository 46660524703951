import { type ComponentProps } from 'solid-js';

import { cn } from '~/ui/methods/classNames';

export const Countdown = (props: ComponentProps<'span'>) => (
  <span {...props} class={cn('countdown font-mono text-2xl', props.class)} />
);

export const CountdownValue = (
  props: Omit<ComponentProps<'span'>, 'children'> & {
    children: number | string;
  },
) => <span {...props} style={{ [`--value`]: props.children }} />;
