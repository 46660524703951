import { type ComponentProps } from 'solid-js';
import { cn } from '~/ui/methods/classNames';

export interface WindowProps extends ComponentProps<'div'> {
  showBackground?: boolean;
  url?: string;
}

export const Window = (props: WindowProps) => (
  <div {...props} class='mockup-window border border-base-300'>
    <div
      class={cn(
        'flex',
        'justify-center',
        'px-4',
        'py-16',
        'border-t',
        'border-base-300',
        {
          'bg-base-300': props.showBackground,
        },
        props.class,
      )}
    >
      {props.children}
    </div>
  </div>
);
