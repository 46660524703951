import type { ComponentProps } from 'solid-js';
import { splitProps } from 'solid-js';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { cn } from '~/ui/methods/classNames';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';

const bottomNavigationVariants = cva('btm-nav', {
  variants: {
    size: {
      xs: 'btm-nav-xs',
      sm: 'btm-nav-sm',
      md: 'btm-nav-md',
      lg: 'btm-nav-lg',
    },
  },
});

export interface BottomNavigationProps
  extends ComponentProps<'nav'>,
    VariantProps<typeof bottomNavigationVariants> {}

export const BottomNavigation = (props: BottomNavigationProps) => (
  <nav
    {...props}
    class={cn(bottomNavigationVariants({ size: props.size }), props.class)}
  />
);

const bottomNavigationButtonVariants = cva('', {
  variants: {
    color: {
      default: '',
      neutral: 'text-neutral',
      primary: 'text-primary',
      secondary: 'text-secondary',
      accent: 'text-accent',
      info: 'text-info',
      success: 'text-success',
      warning: 'text-warning',
      error: 'text-error',
    },
    active: {
      true: 'active',
      false: '',
    },
  },
  defaultVariants: {
    color: 'default',
  },
});
export interface BottomNavigationButtonProps
  extends Omit<ComponentProps<'button'>, 'color'>,
    VariantProps<typeof bottomNavigationButtonVariants> {
  icon: IconDefinition;
}
export const BottomNavigationButton = (props: BottomNavigationButtonProps) => {
  const [styleProps, otherProps] = splitProps(props, ['color']);
  return (
    <button
      {...otherProps}
      class={cn(bottomNavigationButtonVariants(styleProps), props.class)}
    >
      <FontAwesomeIcon
        icon={props.icon}
        class='fill-current w-4 aspect-square'
      />
      <span class='btm-nav-label'>{props.children}</span>
    </button>
  );
};
