import { type ComponentProps } from 'solid-js';
import { cn } from '~/ui/methods/classNames';

export interface BrowserProps extends ComponentProps<'div'> {
  showBackground?: boolean;
  url?: string;
}

export const Browser = (props: BrowserProps) => (
  <div
    {...props}
    class={cn('mockup-browser border border-base-300', {
      'bg-base-300': props.showBackground,
    })}
  >
    <div class='mockup-browser-toolbar'>
      <div class='input'>{props.url}</div>
    </div>
    <div
      class={cn(
        'flex',
        'justify-center',
        'px-4',
        'py-16',
        'border-t',
        'border-base-300',
        {
          'bg-base-200': props.showBackground,
        },
        props.class,
      )}
    >
      {props.children}
    </div>
  </div>
);
