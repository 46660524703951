import { type ComponentProps, splitProps } from 'solid-js';
import { cn } from '~/ui/methods/classNames';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

const stepsVariants = cva('steps', {
  variants: {
    direction: {
      horizontal: 'steps-horizontal',
      vertical: 'steps-vertical',
    },
  },
  defaultVariants: {
    direction: 'horizontal',
  },
});

export interface StepsProps
  extends ComponentProps<'ol'>,
    VariantProps<typeof stepsVariants> {}

export const Steps = (props: StepsProps) => {
  const [styleProps, otherProps] = splitProps(props, ['direction']);
  return (
    <ol {...otherProps} class={cn(stepsVariants(styleProps), props.class)} />
  );
};

const stepVariants = cva('step', {
  variants: {
    color: {
      default: '',
      primary: 'step-primary',
      secondary: 'step-secondary',
      accent: 'step-accent',
      info: 'step-info',
      success: 'step-success',
      warning: 'step-warning',
      error: 'step-error',
    },
  },
  defaultVariants: {
    color: 'default',
  },
});

export interface StepProps
  extends Omit<ComponentProps<'li'>, 'color'>,
    VariantProps<typeof stepVariants> {}

export const Step = (props: StepProps) => {
  const [styleProps, otherProps] = splitProps(props, ['color']);

  return (
    <li {...otherProps} class={cn(stepVariants(styleProps), props.class)} />
  );
};
