import {
  richTextResolver,
  type StoryblokRichTextNode,
} from '@storyblok/richtext';
import { For, isServer, ssr } from 'solid-js/web';
import type { RichTextStoryblok } from '~/storyblok/types';
export interface RichTextProps extends RichTextStoryblok {}

export const RichText = (props: RichTextProps) => {
  const { render } = richTextResolver();
  const content = render(props.content as StoryblokRichTextNode) as string;

  if (!isServer) {
    const dom = new DOMParser().parseFromString(content, 'text/html');
    return (
      <For each={Array.from(dom.querySelectorAll('body > *'))}>
        {(node) => <>{node}</>}
      </For>
    );
  }
  return ssr(content);
};
